import { skeemaApi } from '../skeema'
import {
  PROJECTS_ENDPOINT_PATH,
  PROJECT_ENDPOINT_PATH,
  PROJECT_PAGES_ENDPOINT_PATH,
  PROJECT_PERMISSIONS_ENDPOINT_PATH,
  ProjectEndpointType,
  ProjectPagesEndpointType,
  ProjectPermissionsEndpointType,
  ProjectsEndpointType,
} from '../../../models/endpoints.types'
import {
  adjustProjectTitleForDuplicates,
  getPlaceholderProjectTitle,
} from '../../../utils/projectUtils'
import { TablistPageType } from '../../../models/tablist_pages.types'
import {
  ProjectPageCreationType,
  ProjectPageType,
  ProjectPermissionsType,
  ProjectType,
} from '../../../models/saved_sessions.types'
import { createSelector } from '@reduxjs/toolkit'

export const projectsApi = skeemaApi.injectEndpoints({
  endpoints: (builder) => ({
    getActiveProjects: builder.query<ProjectsEndpointType['GET']['response'], undefined>({
      query: () => {
        return `${PROJECTS_ENDPOINT_PATH}?is_archived=${false}`
      },
    }),
    getArchivedProjects: builder.query<ProjectsEndpointType['GET']['response'], undefined>({
      query: () => {
        return `${PROJECTS_ENDPOINT_PATH}?is_archived=${true}`
      },
    }),
    getProject: builder.query<ProjectEndpointType['GET']['response'], { id: string }>({
      query: ({ id }) => {
        return {
          url: `${PROJECTS_ENDPOINT_PATH}${id}/`,
          method: 'GET',
        }
      },
    }),
    getProjectPages: builder.query<
      ProjectPagesEndpointType['GET']['response'],
      { projectId: string }
    >({
      query: ({ projectId }) => {
        return {
          url: `${PROJECT_PAGES_ENDPOINT_PATH(projectId)}`,
          method: 'GET',
        }
      },
    }),
    createProject: builder.mutation<
      ProjectsEndpointType['POST']['response'],
      {
        tablistPages: TablistPageType[]
        title?: string
        existingProjects?: ProjectType[]
        order?: number
      }
    >({
      query: ({ tablistPages, title, existingProjects, order = 0 }) => {
        let titleOrPlaceholder = title

        if (!title) {
          const existingTitles = existingProjects?.map((project) => project.title) ?? []
          titleOrPlaceholder = adjustProjectTitleForDuplicates(
            existingTitles,
            getPlaceholderProjectTitle(),
          )
        }

        const pages: ProjectPageCreationType[] = tablistPages.map((item) => ({
          title: item.title,
          url: item.url,
          favicon_url: item.favicon_url,
          instance_id: item.instance_id,
        }))

        const projectData: ProjectsEndpointType['POST']['request'] = {
          title: titleOrPlaceholder ?? 'Untitled Project',
          pages,
          order,
        }

        return {
          url: `${PROJECTS_ENDPOINT_PATH}`,
          method: 'POST',
          body: JSON.stringify(projectData),
        }
      },
      onQueryStarted: async (
        { tablistPages: _tablistPages, order = 0 },
        { dispatch, queryFulfilled },
      ) => {
        // only pessimistically update to avoid the problem of not knowing project id
        // albeit this could cause lag of project appearing in the list for users with slow connection
        // TODO: add loading indicator
        try {
          const { data: project } = await queryFulfilled
          dispatch(
            projectsApi.util.updateQueryData(
              'getActiveProjects',
              undefined,
              (draft: ProjectsEndpointType['GET']['response']) => {
                draft.forEach((p) => {
                  if (p.order >= order) {
                    p.order += 1
                  }
                })

                draft.splice(order, 0, project)
              },
            ),
          )

          dispatch(
            projectsApi.util.upsertQueryData('getProject', { id: String(project.id) }, project),
          )

          dispatch(
            projectsApi.util.upsertQueryData('getProjectPages', { projectId: String(project.id) }, [
              ...project.pages,
            ]),
          )
        } catch (e) {
          console.error(e)
        }
      },
    }),
    deleteArchivedProject: builder.mutation<
      ProjectEndpointType['PATCH']['response'],
      { id: string }
    >({
      query: ({ id }) => {
        return {
          url: PROJECT_ENDPOINT_PATH(id),
          method: 'PATCH',
          body: JSON.stringify({ is_deleted: true }),
        }
      },
      onQueryStarted: ({ id }, { dispatch, queryFulfilled }) => {
        try {
          const deleteArchivedProject = dispatch(
            projectsApi.util.updateQueryData('getArchivedProjects', undefined, (draft) => {
              const idx = draft.findIndex((p) => p.id === id)
              if (idx !== -1) {
                draft.splice(idx, 1)
              }
            }),
          )

          const deleteProject = dispatch(
            projectsApi.util.updateQueryData('getProject', { id: String(id) }, () => {
              //TODO: Delete project from cache (not sure if this does anything)
              return undefined
            }),
          )

          queryFulfilled.catch(() => {
            deleteArchivedProject.undo()
            deleteProject.undo()
          })
        } catch (e) {
          console.error(e)
        }
      },
    }),
    archiveProject: builder.mutation<
      ProjectEndpointType['PATCH']['response'],
      { project: ProjectType }
    >({
      query: ({ project }) => {
        return {
          url: PROJECT_ENDPOINT_PATH(project.id),
          method: 'PATCH',
          body: JSON.stringify({ is_archived: true }),
        }
      },
      onQueryStarted: ({ project }, { dispatch, queryFulfilled }) => {
        try {
          const removeFromActiveProjects = dispatch(
            projectsApi.util.updateQueryData('getActiveProjects', undefined, (draft) => {
              const idx = draft.findIndex((p) => p.id === project.id)
              if (idx !== -1) {
                draft.splice(idx, 1)
              }
            }),
          )

          const addToArchivedProjects = dispatch(
            projectsApi.util.updateQueryData('getArchivedProjects', undefined, (draft) => {
              if (project) {
                const newProject = { ...project }
                newProject.is_archived = true
                newProject.datetime_archived = new Date().toISOString()
                draft.push(newProject)

                draft.sort((a, b) => {
                  if (a.datetime_archived && b.datetime_archived) {
                    return (
                      new Date(b.datetime_archived).getTime() -
                      new Date(a.datetime_archived).getTime()
                    )
                  }
                  return 0
                })
              }
            }),
          )

          const archiveProject = dispatch(
            projectsApi.util.updateQueryData('getProject', { id: String(project.id) }, (draft) => {
              draft.is_archived = true
              draft.datetime_archived = new Date().toISOString()
            }),
          )

          queryFulfilled.catch(() => {
            removeFromActiveProjects.undo()
            addToArchivedProjects.undo()
            archiveProject.undo()
          })
        } catch (e) {
          console.error(e)
        }
      },
    }),
    unarchiveProject: builder.mutation<
      ProjectEndpointType['PATCH']['response'],
      { project: ProjectType }
    >({
      query: ({ project }) => {
        return {
          url: PROJECT_ENDPOINT_PATH(project.id),
          method: 'PATCH',
          body: JSON.stringify({ is_archived: false }),
        }
      },
      onQueryStarted: ({ project }, { dispatch, queryFulfilled }) => {
        try {
          const removeFromArchivedProjects = dispatch(
            projectsApi.util.updateQueryData('getArchivedProjects', undefined, (draft) => {
              const idx = draft.findIndex((p) => p.id === project.id)
              if (idx !== -1) {
                draft.splice(idx, 1)
              }
            }),
          )

          const addToActiveProjects = dispatch(
            projectsApi.util.updateQueryData('getActiveProjects', undefined, (draft) => {
              if (project) {
                const newProject = { ...project }
                newProject.order = 0
                newProject.is_archived = false
                newProject.datetime_archived = null
                draft.forEach((p) => {
                  p.order += 1
                })
                draft.push(newProject)

                draft.sort((a, b) => {
                  return a.order - b.order
                })
              }
            }),
          )

          const unarchiveProject = dispatch(
            projectsApi.util.updateQueryData('getProject', { id: String(project.id) }, (draft) => {
              draft.is_archived = false
              draft.datetime_archived = null
            }),
          )

          queryFulfilled.catch(() => {
            unarchiveProject.undo()
            removeFromArchivedProjects.undo()
            addToActiveProjects.undo()
          })
        } catch (e) {
          console.error(e)
        }
      },
    }),
    renameProject: builder.mutation<
      ProjectEndpointType['PATCH']['response'],
      { id: string; title: string }
    >({
      query: ({ id, title }) => {
        return {
          url: PROJECT_ENDPOINT_PATH(id),
          method: 'PATCH',
          body: JSON.stringify({ title }),
        }
      },
      onQueryStarted: ({ id, title }, { dispatch, queryFulfilled }) => {
        try {
          const renameProjectInListCallback = (draft: ProjectsEndpointType['GET']['response']) => {
            const project = draft.find((p) => p.id === id)
            if (project) {
              project.title = title
            }
          }
          const renameActiveProject = dispatch(
            projectsApi.util.updateQueryData(
              'getActiveProjects',
              undefined,
              renameProjectInListCallback,
            ),
          )

          const renameArchivedProject = dispatch(
            projectsApi.util.updateQueryData(
              'getArchivedProjects',
              undefined,
              renameProjectInListCallback,
            ),
          )

          const renameProject = dispatch(
            projectsApi.util.updateQueryData('getProject', { id: String(id) }, (draft) => {
              draft.title = title
            }),
          )

          queryFulfilled.catch(() => {
            renameActiveProject.undo()
            renameArchivedProject.undo()
            renameProject.undo()
          })
        } catch (e) {
          console.error(e)
        }
      },
    }),
    moveProject: builder.mutation<
      ProjectEndpointType['PATCH']['response'],
      { id: string; order: number }
    >({
      query: ({ id, order }) => {
        return {
          url: PROJECT_ENDPOINT_PATH(id),
          method: 'PATCH',
          body: JSON.stringify({ order }),
        }
      },
      onQueryStarted: ({ id, order }, { dispatch, queryFulfilled }) => {
        try {
          const optimisticallyUpdateQuery = (draft: ProjectsEndpointType['GET']['response']) => {
            const idx = draft.findIndex((p) => p.id === id)
            if (idx === -1) {
              return
            }

            const project = draft[idx]
            const oldOrder = project.order
            const newOrder = order

            if (oldOrder === newOrder) {
              return
            }

            draft.forEach((p) => {
              if (oldOrder < newOrder) {
                if (p.order > oldOrder && p.order <= newOrder) {
                  p.order -= 1
                }
              } else {
                if (p.order < oldOrder && p.order >= newOrder) {
                  p.order += 1
                }
              }
            })

            project.order = newOrder

            draft.sort((a, b) => a.order - b.order)
          }

          const reorderActiveProjects = dispatch(
            projectsApi.util.updateQueryData(
              'getActiveProjects',
              undefined,
              optimisticallyUpdateQuery,
            ),
          )

          queryFulfilled.catch(() => {
            reorderActiveProjects.undo()
          })
        } catch (e) {
          console.error(e)
        }
      },
    }),
    addProjectPages: builder.mutation<
      ProjectPagesEndpointType['POST']['response'],
      {
        tablistPages: TablistPageType[]
        maxNumProjectPages: number
        project: ProjectType
        index?: number
      }
    >({
      query: ({ project, tablistPages, maxNumProjectPages, index }) => {
        const totalNumPages = project.pages.length + tablistPages.length
        if (totalNumPages > maxNumProjectPages) {
          const msg = `Error: Adding pages would exceed the project's maximum page limit`
          console.error(msg)
          return msg
        }

        const pages = tablistPages.map(
          (item): ProjectPageCreationType => ({
            title: item.title,
            url: item.url,
            favicon_url: item.favicon_url,
            instance_id: item.instance_id,
          }),
        )

        const data: ProjectPagesEndpointType['POST']['request'] = {
          pages,
          index,
        }

        return {
          url: `${PROJECT_PAGES_ENDPOINT_PATH(project.id)}`,
          method: 'POST',
          body: JSON.stringify(data),
        }
      },
      onQueryStarted: async (
        { project, index, tablistPages: _tablistPages, maxNumProjectPages: _maxNumProjectPages },
        { dispatch, queryFulfilled },
      ) => {
        try {
          const { data: pages } = await queryFulfilled

          const addPageToProjectsListCallback = (
            draft: ProjectsEndpointType['GET']['response'],
          ) => {
            const cachedProject = draft.find((p) => p.id === project.id)

            if (cachedProject) {
              const idx = index ?? 0
              const newPages = cachedProject.pages.map((p) => {
                if (p.order >= idx) {
                  return { ...p, order: p.order + pages.length }
                }
                return p
              })
              newPages.splice(idx, 0, ...pages)
              cachedProject.pages = newPages
            }
          }

          const addToActiveProjects = dispatch(
            projectsApi.util.updateQueryData(
              'getActiveProjects',
              undefined,
              addPageToProjectsListCallback,
            ),
          )

          const addToArchivedProjects = dispatch(
            projectsApi.util.updateQueryData(
              'getArchivedProjects',
              undefined,
              addPageToProjectsListCallback,
            ),
          )

          const addToProjectPages = dispatch(
            projectsApi.util.updateQueryData(
              'getProjectPages',
              { projectId: String(project.id) },
              (draft: ProjectPagesEndpointType['GET']['response']) => {
                const idx = index ?? 0
                draft.splice(idx, 0, ...pages)
                draft.forEach((p) => {
                  if (p.order >= idx) {
                    p.order += pages.length
                  }
                })
              },
            ),
          )

          const addToProject = dispatch(
            projectsApi.util.updateQueryData(
              'getProject',
              { id: String(project.id) },
              (draft: ProjectEndpointType['GET']['response']) => {
                const idx = index ?? 0
                draft.pages.splice(idx, 0, ...pages)
                draft.pages.forEach((p) => {
                  if (p.order >= idx) {
                    p.order += pages.length
                  }
                })
              },
            ),
          )

          queryFulfilled.catch(() => {
            addToActiveProjects.undo()
            addToArchivedProjects.undo()
            addToProjectPages.undo()
            addToProject.undo()
          })
        } catch (e) {
          console.error(e)
        }
      },
    }),
    deleteProjectPage: builder.mutation<
      ProjectPagesEndpointType['DELETE']['response'],
      { projectId: string; pageId: string }
    >({
      query: ({ projectId, pageId }) => {
        return {
          url: `${PROJECT_PAGES_ENDPOINT_PATH(projectId)}${pageId}/`,
          method: 'DELETE',
        }
      },
      onQueryStarted: ({ projectId, pageId }, { dispatch, queryFulfilled }) => {
        try {
          const deleteFromProjectsCb = (draft: ProjectsEndpointType['GET']['response']) => {
            const idx = draft.findIndex((p) => p.id === projectId)
            if (idx !== -1) {
              const project = draft[idx]
              const pageIdx = project.pages.findIndex((p) => p.id === pageId)
              if (pageIdx !== -1) {
                project.pages.splice(pageIdx, 1)
                project.pages.forEach((p) => {
                  if (p.order >= pageIdx) {
                    p.order -= 1
                  }
                })
              }
            }
          }

          const deleteFromActiveProjects = dispatch(
            projectsApi.util.updateQueryData('getActiveProjects', undefined, deleteFromProjectsCb),
          )

          const deleteFromArchivedProjects = dispatch(
            projectsApi.util.updateQueryData(
              'getArchivedProjects',
              undefined,
              deleteFromProjectsCb,
            ),
          )

          const deleteFromProject = dispatch(
            projectsApi.util.updateQueryData(
              'getProject',
              { id: String(projectId) },
              (draft: ProjectEndpointType['GET']['response']) => {
                const idx = draft.pages.findIndex((p) => p.id === pageId)
                if (idx !== -1) {
                  draft.pages.splice(idx, 1)
                  draft.pages.forEach((p) => {
                    if (p.order >= idx) {
                      p.order -= 1
                    }
                  })
                }
              },
            ),
          )

          const deleteFromProjectPages = dispatch(
            projectsApi.util.updateQueryData(
              'getProjectPages',
              { projectId: String(projectId) },
              (draft: ProjectPagesEndpointType['GET']['response']) => {
                const idx = draft.findIndex((p) => p.id === pageId)
                if (idx !== -1) {
                  draft.splice(idx, 1)
                  draft.forEach((p) => {
                    if (p.order >= idx) {
                      p.order -= 1
                    }
                  })
                }
              },
            ),
          )

          queryFulfilled.catch(() => {
            deleteFromActiveProjects.undo()
            deleteFromArchivedProjects.undo()
            deleteFromProject.undo()
            deleteFromProjectPages.undo()
          })
        } catch (e) {
          console.error(e)
        }
      },
    }),
    moveProjectPage: builder.mutation<
      ProjectPagesEndpointType['PUT']['response'],
      {
        projectId: string
        page: ProjectPageType
        index: number
        destProject: ProjectType
        maxNumProjectPages: number
      }
    >({
      query: ({ projectId, page, index, destProject, maxNumProjectPages }) => {
        const totalNumPages = destProject.pages.length + 1
        if (totalNumPages > maxNumProjectPages) {
          const msg = `Error: Adding pages would exceed the dest project's maximum page limit`
          console.error(msg)
          return msg
        }

        const data: ProjectPagesEndpointType['PUT']['request'] = {
          index,
          project_id: destProject.id,
        }

        return {
          url: `${PROJECT_PAGES_ENDPOINT_PATH(projectId)}${page.id}/`,
          method: 'PUT',
          body: JSON.stringify(data),
        }
      },
      onQueryStarted: ({ projectId, page, index, destProject }, { dispatch, queryFulfilled }) => {
        try {
          const destProjectId = destProject.id
          const isMovingProjects = projectId !== destProjectId
          const destIdx = !isMovingProjects && page.order < index ? index - 1 : index

          const removePageFromSource = (pages: ProjectPageType[]) => {
            const pageIdx = pages.findIndex((p) => p.id === page.id)
            if (pageIdx !== -1) {
              pages.splice(pageIdx, 1)
              pages.forEach((p) => {
                if (p.order >= page.order) {
                  p.order -= 1
                }
              })
            }
          }

          const addPageToDest = (pages: ProjectPageType[]) => {
            pages.forEach((p) => {
              if (p.order >= destIdx) {
                p.order += 1
              }
            })
            pages.splice(destIdx, 0, { ...page, order: destIdx })
          }

          const movePageWithinProjectsQueryCallback = (
            draft: ProjectsEndpointType['GET']['response'],
          ) => {
            const sourceProjectDraft = draft.find((p) => p.id === projectId)
            const destProjectDraft = draft.find((p) => p.id === destProjectId)

            if (sourceProjectDraft) {
              removePageFromSource(sourceProjectDraft.pages)
            }
            if (destProjectDraft) {
              addPageToDest(destProjectDraft.pages)
            }
          }

          const moveWithinActiveProjects = dispatch(
            projectsApi.util.updateQueryData(
              'getActiveProjects',
              undefined,
              movePageWithinProjectsQueryCallback,
            ),
          )
          const moveWithinArchivedProjects = dispatch(
            projectsApi.util.updateQueryData(
              'getArchivedProjects',
              undefined,
              movePageWithinProjectsQueryCallback,
            ),
          )
          const moveOutOfProject = dispatch(
            projectsApi.util.updateQueryData('getProject', { id: String(projectId) }, (draft) => {
              removePageFromSource(draft.pages)
            }),
          )
          const moveIntoProject = dispatch(
            projectsApi.util.updateQueryData(
              'getProject',
              { id: String(destProjectId) },
              (draft) => {
                addPageToDest(draft.pages)
              },
            ),
          )

          const moveOutOfProjectPages = dispatch(
            projectsApi.util.updateQueryData(
              'getProjectPages',
              { projectId: String(projectId) },
              (draft) => {
                removePageFromSource(draft)
              },
            ),
          )
          const moveIntoProjectPages = dispatch(
            projectsApi.util.updateQueryData(
              'getProjectPages',
              { projectId: String(destProjectId) },
              (draft) => {
                addPageToDest(draft)
              },
            ),
          )

          queryFulfilled.catch(() => {
            moveWithinActiveProjects.undo()
            moveWithinArchivedProjects.undo()
            moveOutOfProject.undo()
            moveIntoProject.undo()
            moveOutOfProjectPages.undo()
            moveIntoProjectPages.undo()
          })
        } catch (e) {
          console.error(e)
        }
      },
    }),
    getProjectPermissions: builder.query<
      ProjectPermissionsEndpointType['GET']['response'],
      { projectId: string }
    >({
      query: ({ projectId }) => {
        return {
          url: PROJECT_PERMISSIONS_ENDPOINT_PATH(projectId),
        }
      },
      providesTags: (result, error, { projectId }) => [
        { type: 'ProjectPermissions', id: projectId },
      ],
    }),
    setProjectPermissions: builder.mutation<
      ProjectPermissionsEndpointType['POST']['response'],
      { projectId: string; permission: ProjectPermissionsType }
    >({
      query: ({ projectId, permission }) => {
        return {
          url: PROJECT_PERMISSIONS_ENDPOINT_PATH(projectId),
          method: 'POST',
          body: JSON.stringify({ target: permission.target, permission: permission.permission }),
        }
      },
      invalidatesTags: (result, error, { projectId }) => [
        { type: 'ProjectPermissions', id: projectId },
      ],
    }),
  }),
})

const selectGetActiveProjectsQueryResult = projectsApi.endpoints.getActiveProjects.select(undefined)
export const selectNumActiveProjects = createSelector(
  selectGetActiveProjectsQueryResult,
  (result) => {
    return result.data?.length
  },
)

export const {
  useGetActiveProjectsQuery,
  useGetArchivedProjectsQuery,
  useGetProjectQuery,
  useGetProjectPagesQuery,
  useArchiveProjectMutation,
  useUnarchiveProjectMutation,
  useDeleteArchivedProjectMutation,
  useCreateProjectMutation,
  useRenameProjectMutation,
  useMoveProjectMutation,
  useAddProjectPagesMutation,
  useDeleteProjectPageMutation,
  useMoveProjectPageMutation,
  useLazyGetActiveProjectsQuery,
  useGetProjectPermissionsQuery,
  useSetProjectPermissionsMutation,
} = projectsApi
