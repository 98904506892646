import React, { FC, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { sendMessageToExtension } from '../../utils/externalMessaging'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../../../extension/models/messaging.types'
import { useExtensionHealthContext } from '../../../contexts/ExtensionHealthContext'
import { APP_LOGO_WORDMARK_URL_SKIPPER } from '../../../constants'
import { Box, Flex, Grid, GridItem, Img, Spinner, Link } from '@chakra-ui/react'
import { useUserContext } from '../../../contexts/UserContext'
import { useAuthContext } from '../../../contexts/AuthContext'

const AuthSyncPage: FC = () => {
  const { captureAnalytics } = useUserContext()
  const { extensionHealth } = useExtensionHealthContext()

  const didHandlerRun = useRef<boolean>(false)
  const navigate = useNavigate()
  const { signIn } = useAuthContext()

  useEffect(() => {
    //--------------------------------------
    async function getExtensionAuth() {
      try {
        const token = (await sendMessageToExtension(
          BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.GET_AUTH_TOKEN,
        )) as string | undefined

        if (!token) {
          console.error('Failed to retrieve auth token from extension')
          return false
        }
        const signInResult = signIn(token)

        if (!signInResult) {
          console.error('FAILURE: Token retrieved, but client signIn failed')
          return false
        }
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    }
    //--------------------------------------

    if (extensionHealth && !didHandlerRun.current) {
      void getExtensionAuth()
        .then((res) => {
          captureAnalytics('auth_sync_page:result_loaded', {
            wasSuccessful: res,
          })
        })
        .catch(console.error)
        .finally(() => {
          navigate('/', { replace: true })
        })
      didHandlerRun.current = true
    }
  }, [captureAnalytics, extensionHealth, navigate, signIn])

  return (
    <Flex direction="row" alignItems="center" h="100%" w="100%">
      <Grid
        height="100%"
        width="100%"
        templateColumns="repeat(12, 1fr)"
        templateRows="68px auto"
        flex="1 1 auto"
      >
        <GridItem colSpan={12} gridRow={1} rowSpan={1} display="flex">
          <Box display={'flex'} m="32px 0 0 24px">
            <Link href="/">
              <Img src={APP_LOGO_WORDMARK_URL_SKIPPER} alt={`Skipper logo`} height={'22px'} />
            </Link>
          </Box>
        </GridItem>

        <GridItem
          className="scrollbars"
          colStart={4}
          gridRow={2}
          colSpan={6}
          height="100%"
          overflow="auto"
          minWidth="552px"
          maxWidth="1000px"
        >
          <div className="flex-center" style={{ width: '100%', marginTop: '32px' }}>
            <Spinner color="blue.500" size="lg" speed="1s" />
          </div>
        </GridItem>
      </Grid>
    </Flex>
  )
}

export default AuthSyncPage
