import React, { useState, useContext, FC, useEffect, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { clearAuthHeader, setAuthHeader, setInstantSaveQuota } from '../redux/userSlice'
import { DateTime } from 'luxon'
import { getUserInfo } from '../apiClient/skeema/fetchWrappers'
import { UserInfoType } from '../models/user.types'
import { useNavigate } from 'react-router-dom'
import { usePostHog } from 'posthog-js/react'
import { WEBAPP_SESSION_STORAGE_KEYS } from '../models/browserStorage.types'
import { useExtensionHealthContext } from './ExtensionHealthContext'
import { BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS } from '../extension/models/messaging.types'
import { sendMessageToExtension } from '../webapp/utils/externalMessaging'
import {
  CURRENT_ENV,
  MINIMUM_EXTENSION_VERSION,
  WEBAPP_BASE_URL_SKEEMA,
  WEBAPP_BASE_URL_SKIPPER,
} from '../constants'
import * as Sentry from '@sentry/react'
import { useAuthContext } from './AuthContext'
import { useGetActionQuotaQuery } from '../redux/services/skeema/usage_service.endpoints'
import { useReduxSelector } from '../redux/baseStore'

const EXTENSION_NOT_REQUIRED_URLS: string[] = [
  `${WEBAPP_BASE_URL_SKEEMA}/extension/uninstalled`,
  `${WEBAPP_BASE_URL_SKEEMA}/projects`,
  `${WEBAPP_BASE_URL_SKIPPER}/extension/uninstalled`,
  `${WEBAPP_BASE_URL_SKIPPER}/projects`,
]

type UserContextType = {
  userInfo: UserInfoType | undefined | null
  isExtensionRequired: boolean | undefined
  captureAnalytics: (event: string, properties?: Record<string, unknown>) => void
  signOut: () => void
}

export const UserContext = React.createContext<UserContextType>({
  userInfo: undefined,
  isExtensionRequired: undefined,
  captureAnalytics: () => {},
  signOut: () => {
    console.error('ERROR: UserContext signOut function called before being initialized')
    return Promise.resolve()
  },
})

export const useUserContext = (): UserContextType => {
  return useContext(UserContext)
}

export const UserContextProvider: FC<{ isInExtension: boolean; children: React.ReactNode }> = ({
  isInExtension,
  children,
}): React.JSX.Element => {
  const [userInfo, setUserInfo] = useState<null | undefined | UserInfoType>(undefined)
  const navigate = useNavigate()
  const isExtensionRequired = isInExtension
    ? undefined
    : !EXTENSION_NOT_REQUIRED_URLS.some((url) => window.location.href.startsWith(url))

  const posthog = usePostHog()
  const { extensionHealth, forceReload: forceExtensionHealthReload } = useExtensionHealthContext()

  const { authHeader, isAuthenticated, signOut: _signOut } = useAuthContext()

  // The number of auth related states is getting out of control... need refactoring
  const storedAuthHeader = useReduxSelector((store) => store.user.authHeader)
  const { data: actionQuota } = useGetActionQuotaQuery(undefined, {
    skip: !storedAuthHeader,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setAuthHeader(authHeader))
    if (authHeader) {
      dispatch(setInstantSaveQuota(actionQuota?.remaining_quota))
    }
  }, [actionQuota?.remaining_quota, authHeader, dispatch])

  const signOut = useCallback(() => {
    _signOut()
    dispatch(clearAuthHeader())
    setUserInfo(null)
    //TODO: message extension to clear auth
    window.sessionStorage.removeItem(WEBAPP_SESSION_STORAGE_KEYS.SkeemaUserBrowserSession)
    posthog.reset()
    navigate('/extension/installed')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, dispatch, posthog])

  const captureAnalytics = useCallback(
    (event: string, properties?: Record<string, unknown>) => {
      const timeSinceSignUp = userInfo?.date_joined
        ? Math.floor(DateTime.now().toMillis() - DateTime.fromISO(userInfo.date_joined).toMillis())
        : undefined
      const updatedProperties = {
        ...properties,
        timeSinceSignUp,
      }
      if (CURRENT_ENV !== 'prod') {
        console.log('Capturing analytics event', event, updatedProperties)
      }
      posthog.capture(event, updatedProperties)
    },
    [posthog, userInfo],
  )

  const initSession = useCallback(
    (userInfo: UserInfoType) => {
      const sessionId = window.sessionStorage.getItem(
        WEBAPP_SESSION_STORAGE_KEYS.SkeemaUserBrowserSession,
      )
      console.log('User', userInfo, 'Session ID', sessionId)
      if (sessionId === null) {
        posthog.identify(userInfo.email, userInfo)
        Sentry.setUser({ email: userInfo.email })
        const sessionId = Math.random().toString(36).substring(2, 15)
        window.sessionStorage.setItem(
          WEBAPP_SESSION_STORAGE_KEYS.SkeemaUserBrowserSession,
          sessionId,
        )
        console.log('INIT SESSION: ', sessionId)
      }
    },
    [posthog],
  )

  useEffect(() => {
    if (!isAuthenticated || !authHeader) {
      setUserInfo(null)
      return
    }

    if (isExtensionRequired && extensionHealth === undefined) {
      return
    }

    const timezone = DateTime.now().zoneName
    const headers = { Authorization: authHeader }
    getUserInfo(headers, extensionHealth ? extensionHealth.version : undefined, timezone)
      .then((userInfo) => {
        console.log('User info USERCONTEXT', userInfo)
        setUserInfo(userInfo.data)
        initSession(userInfo.data)
      })
      .catch((err) => {
        console.error(err)
        // we used to sign user out here, which is not the best idea
        // sometimes it's okay to not be able to fetch user info if it's already known locally
        // even when it's fatal we should show an appropriate error message instead
      })
  }, [authHeader, extensionHealth, initSession, isAuthenticated, isExtensionRequired, signOut])

  useEffect(() => {
    console.log('EXTENSION HEALTH: ', userInfo, extensionHealth)
    if (isInExtension) {
      return
    }

    if (!isExtensionRequired) {
      console.log('Extension not required')
      return
    }

    if (extensionHealth === undefined) {
      // Extension health is not initialized
      return
    }
    if (extensionHealth === null) {
      // Extension health failed to initialize
      navigate('/extension/install')
      return
    }
    if (extensionHealth.version < MINIMUM_EXTENSION_VERSION) {
      console.log(
        `Extension version ${extensionHealth.version} is less than minimum required version ${MINIMUM_EXTENSION_VERSION}`,
      )
      navigate('/extension/update')
      return
    }

    if (!userInfo) {
      return
    }

    if (
      authHeader !== undefined &&
      (extensionHealth.userInfo === undefined || extensionHealth.userInfo.email !== userInfo.email)
    ) {
      // Extension is either not logged in or logged in as a different user
      // Update extension auth with webapp auth
      const token = authHeader.replace('Bearer', '').trim()
      sendMessageToExtension(BACKGROUND_ON_MESSAGE_LISTENER_ACTIONS.UPDATE_AUTH, {
        token,
      })
        .then(() => {
          console.log('Updated extension auth with webapp token')

          forceExtensionHealthReload()
        })
        .catch((e) => {
          //Something went wrong, log out user
          console.error('ERROR: Failed to update faulty extension auth', e)
          void signOut()
        })

      return
    }

    posthog.identify(userInfo.email, {
      extensionVersion: extensionHealth.version,
    })
  }, [
    authHeader,
    extensionHealth,
    forceExtensionHealthReload,
    isExtensionRequired,
    navigate,
    signOut,
    userInfo,
    posthog,
    isInExtension,
  ])

  const value = useMemo(
    () => ({
      userInfo,
      signOut,
      isExtensionRequired,
      captureAnalytics,
    }),
    [userInfo, signOut, isExtensionRequired, captureAnalytics],
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
