export const {
  CURRENT_ENV,
  EXTENSION_VERSION,
  MINIMUM_EXTENSION_VERSION,
  EXTENSION_ID,
  OAUTH_WEB_CLIENT_ID,
  WEBAPP_BASE_URL,
  WEBAPP_BASE_URL_SKEEMA,
  WEBAPP_BASE_URL_SKIPPER,
  API_SERVER_URL,
  ENABLE_POSTHOG,
  POSTHOG_HOST,
  POSTHOG_KEY,
  ENABLE_SENTRY,
  SENTRY_DSN,
  ENABLE_META_PIXEL,
  META_PIXEL_ID,
  ENABLE_GOOGLE_ANALYTICS,
  GOOGLE_ANALYTICS_ID,
  ENABLE_CONSOLE_LOG,
  STRIPE_PUBLISHABLE_KEY,
  PUTAWAY_ALARM_DELAY_IN_MIN,
  PUTAWAY_ALARM_PERIOD_IN_MIN,
} = __ENV_VAR__
export const TOLERATED_TAB_URLS = [`${WEBAPP_BASE_URL}/projects`, `${WEBAPP_BASE_URL}/auth/handler`]
export const CHROME_STORE_URL = `https://chrome.google.com/webstore/detail/${EXTENSION_ID}`

// Backward compatibility old constants
export const APP_NAME_SHORT = 'sk'
export const FEEDBACK_FORM_URL = 'https://forms.gle/9UvMS46f6N2fsGiN6'
export const HOW_TO_URL =
  'https://skeema.notion.site/Skeema-Getting-Started-Guide-8ceb9ad1509b4e7eaeb61bbc997d0e00?pvs=4'
export const UNINSTALL_FEEDBACK_FORM_URL = 'https://forms.gle/ZF5aPhFqSR4LDnVA9'
//--------------------------------

// Skeema assets
export const FEEDBACK_FORM_URL_SKEEMA = 'https://forms.gle/9UvMS46f6N2fsGiN6'
export const HOW_TO_URL_SKEEMA =
  'https://skeema.notion.site/Skeema-Getting-Started-Guide-8ceb9ad1509b4e7eaeb61bbc997d0e00?pvs=4'
export const UNINSTALL_FEEDBACK_FORM_URL_SKEEMA = 'https://forms.gle/ZF5aPhFqSR4LDnVA9'
export const APP_NAME_SKEEMA = 'Skeema'
export const APP_LOGO_WORDMARK_URL_SKEEMA = '/logo-wordmark-skeema.svg'
export const INSTANT_SAVE_WINDOWS_IMG_URL_SKEEMA = '/instant-save-windows-skeema.png'
export const INSTANT_SAVE_MAC_IMG_URL_SKEEMA = '/instant-save-mac-skeema.png'
export const SUPPORT_EMAIL_SKEEMA = 'support@skeema.com'
export const AUTH_REDIRECT_URL_SKEEMA = `${WEBAPP_BASE_URL_SKEEMA}/auth/handler`
//--------------------------------

// Skipper assets
export const FEEDBACK_FORM_URL_SKIPPER = 'https://forms.gle/qbX7dTaxeWCZzPYp6'
export const HOW_TO_URL_SKIPPER =
  'https://docs.skipper.co/Skipper-Getting-Started-Guide-10a5d2243c8a8027b110cf3a55a66782'
export const UNINSTALL_FEEDBACK_FORM_URL_SKIPPER = 'https://forms.gle/ssyFK7ZHvTog8iGT8'
export const APP_NAME_SKIPPER = 'Skipper'
export const APP_LOGO_WORDMARK_URL_SKIPPER = '/logo-wordmark-skipper.svg'
export const INSTANT_SAVE_WINDOWS_IMG_URL_SKIPPER = '/instant-save-windows-skipper.png'
export const INSTANT_SAVE_MAC_IMG_URL_SKIPPER = '/instant-save-mac-skipper.png'
export const SUPPORT_EMAIL_SKIPPER = 'hello@skipper.co'
export const AUTH_REDIRECT_URL_SKIPPER = `${WEBAPP_BASE_URL_SKIPPER}/auth/handler`
//--------------------------------

export const APP_LOGO_WORDMARK_URL_LOADING = '/logo-wordmark-loading.svg'

export const GIT_COMMIT_HASH = __COMMIT_HASH__
