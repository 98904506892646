export interface TablistPageType {
  id: string
  title: string
  location?: string
  url: string
  favicon_url?: string
  last_access_timestamp_ms: number
  last_access_datetime_utc_iso: string
  entity_type: TABLIST_PAGE_ENUM
  instance_id?: string
  is_toread?: boolean
  is_manual?: boolean
  is_audible?: boolean
  is_open?: boolean
  app_name?: string
  is_pinned?: boolean
  window_id?: number
  group_id?: number
  index?: number
  project_id?: string
}

export enum TABLIST_PAGE_ENUM {
  OPEN = 'OPEN',
  MOST_VISITED = 'MOST_VISITED',
  RECENTLY_USED = 'RECENTLY_USED',
  SMART_SESSION = 'SMART_SESSION',
  PROJECT_PAGE = 'PROJECT_PAGE',
}

export interface TablistPageSearchResultType extends TablistPageType {
  title_highlights?: [number, number][]
}
